import React, { useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Calendar, momentLocalizer, Views } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { cartContext } from "../context/cartContext/cartContext"
import { searchContext } from "../context/searchContext/searchContext"
import { userContext } from "../context/userContext/userContext"
import axios from "axios"
import { base, URLS } from "../config/urls"
import { navigate } from "gatsby"
import {showError} from "../uitlity/Utility"
import { razorpayContext } from "../context/razorpayContext/razorpayContext"
import Loader from "../components/loader/Loader"


const localizer = momentLocalizer(moment) // or globalizeLocalizer

const BookingReschedule = (props) => {
  let bookingData= null
  let subscriptionData = null
  if (typeof window !== "undefined") {
    try{
      bookingData = props.location.state.bookingData
    }catch(e){}
    try{
      subscriptionData = props.location.state.subscriptionData
    }catch(e){}

  }

  const searchCtx = useContext(searchContext)
  const userCtx = useContext(userContext)
  const razorCtx = useContext(razorpayContext)

  const [salon, setSalon] = useState(null)
  const [bookingDate, setBookingDate] = useState(new Date())
  const [selectedService, setSelectedService] = useState(0)
  const [selectedPackage, setSelectedPackage] = useState(0)
  const [resources, setResources] = useState([])
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState({
    packages: {},
    services: {}
  })
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [allSlotsSelected, setAllSlotsSelected] = useState(false)

  let content = null
  if (!salon) {
    content = <Loader/>
  }
  const setHours = (hours) => {
    let splitHours = hours.split(":")
    return new Date(bookingDate).setHours(splitHours[0], splitHours[1], splitHours[2])
  }

  let serviceList =[]
  let packageList =[]
  let paid = false


  if(bookingData){
    serviceList=bookingData.servicesAndPackages.filter(
      serviceOrPackage => serviceOrPackage["is_package"] === false)
    let serviceObject = {}
    serviceList.forEach(serviceDetails => {
      if (serviceObject[serviceDetails.service.id]) {
        serviceObject[serviceDetails.service.id].persons = serviceObject[serviceDetails.service.id].persons + 1
      } else {
        serviceObject[serviceDetails.service.id] = serviceDetails.service
        serviceObject[serviceDetails.service.id].persons = 1
      }
    })
    serviceList = Object.keys(serviceObject).map(serviceId => serviceObject[serviceId])
    bookingData.servicesAndPackages.filter(
      serviceOrPackage => serviceOrPackage["is_package"] === true)
    let packageObject = {}
    packageList.forEach(packageDetails => {
      if (packageObject[packageDetails["package"].id]) {
        packageObject[packageDetails["package"].id].persons = packageObject[packageDetails["package"].id].persons + 1
      } else {
        packageObject[packageDetails["package"].id] = packageDetails["package"]
        packageObject[packageDetails["package"].id].persons = 1
      }
    })
    packageList = Object.keys(packageObject).map(packageId => packageObject[packageId])
    paid=bookingData.paid
  }else if(subscriptionData){
    serviceList = subscriptionData.services
    serviceList.forEach(service =>{
      service.persons=1
    })
    packageList = subscriptionData.packages
    packageList.forEach((singlePackage)=>singlePackage.persons=1)
    paid=subscriptionData.paid
  }


  let charges = 0
  let discount = 0
  let total = 0
  let handlingCharges = 0
  if ((bookingData && !bookingData.paid)||(subscriptionData && !subscriptionData.paid)) {
    charges = serviceList.length && serviceList.reduce((prev, service) => prev + service.price, 0)
    charges = charges + (packageList.length && packageList.reduce((prev, singlePackage) => prev + singlePackage.price, 0))
    total = charges
    discount = 0
    handlingCharges = 10
  }


  useEffect(() => {
    if(!bookingData&&!subscriptionData){
      navigate(base +'/')
      return
    }

    let salonId=0
    if(bookingData)salonId=bookingData.salonId
    else if(subscriptionData)salonId = subscriptionData.salon.id
    searchCtx.getSalon(salonId).then(salon => {
      setSalon(salon)
    })
  }, [])

  const prepareBookingObject = (payment) => {

    let rebookingObject = {
      "booking_date": moment(bookingDate).format("YYYY-MM-DD"),
      "bookings": Object.keys(selectedEvent).reduce((prev, type) => {
        let outerBookings = Object.keys(selectedEvent[type]).reduce((prev1, typeId) => {
          let partialBookings = selectedEvent[type][typeId].map((typeDetails) => {
            let start = new Date(typeDetails.start)
            let end = new Date(typeDetails.end)
            let internalBooking = {
              "stylist_id": typeDetails.resourceId,
              // `${type.slice(0,-1)}_id`:typeId,
              "start_time": `${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`,
              "end_time": `${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`
            }
            internalBooking[`${type.slice(0, -1)}_id`] = parseInt(typeId)
            return internalBooking
          })
          return prev1.concat(partialBookings)
        }, [])
        return prev.concat(outerBookings)
      }, []),
    }

    if(bookingData)
      rebookingObject["prev_booking_id"] = bookingData.bookingId
    if(bookingData.subscriptionId){
      rebookingObject["subscription_id"] = bookingData.subscriptionId
    }

    ////debugger
    let headers = {}
    headers["Authorization"] = "Token " + userCtx.token

    axios.post(URLS.reschedulingUrl(),
      rebookingObject, {
        headers: headers
      }).then(({ data }) => {
      let bookingId = data.data["booking_id"]
      //debugger
      if (payment) {
        razorCtx.displayRazorpay({
          bookingId:data.data["id"],
          "first_name":userCtx.user["first_name"],
          "phone_number":userCtx.user["phone_number"],
          "email":userCtx.user["email"],
        },()=>{
          navigate(base +"/bookingDone", {
            replace: true,
            state: {
              salonName: salon.name,
              bookingId: bookingId
            }
          })
        },(e)=> {
          showError(e)
          navigate(base +"/bookingDone", {
            replace: true,
            state: {
              salonName: salon.name,
              bookingId: bookingId
            }
          })
        })

      } else {
        navigate(base +"/bookingDone", {
          replace: true,
          state: {
            salonName: salon.name,
            bookingId: bookingId
          }
        })
      }

    }).catch((error) => {
      let response = { ...error.response }
      if (response.status === 400) {
        showError(response.data.msg)
      } else {
        showError("Unable to reach server Please try again later")
      }
    })
  }



  const serviceSelected = serviceId => {
    if (salon) {
      let activeStylists = salon.stylists.filter(stylist => {
        for (let service of stylist.services) {
          if (service === serviceId) return true
        }
      })
      let resourceMap = activeStylists.map(stylist => {
        return { resourceId: stylist.id, resourceTitle: stylist.name }
      })

      let calenderEvents = []
      //////debugger
      salon.bookings.forEach((booking) => { // creating active booking separated by stylists
        calenderEvents.push({
          id: booking.id,
          title: "Booked",
          start: new Date(booking.date + " " + booking.start_time),
          end: new Date(booking.date + " " + booking.end_time),
          resourceId: booking.stylist.id
        })
      })

      setResources(resourceMap)
      setEvents(calenderEvents)
      setSelectedPackage(-1)
      setSelectedService(serviceId)
    }
  }
  const packageSelected = packageId => {
    if (salon) {
      let activeStylists = salon.stylists.filter(stylist => {
        for (let singlePackage of stylist.packages) {
          if (singlePackage === packageId) return true
        }
      })
      let resourceMap = activeStylists.map(stylist => {
        return { resourceId: stylist.id, resourceTitle: stylist.name }
      })

      let calenderEvents = []
      //////debugger
      salon.bookings.forEach((booking) => { // creating active booking separated by stylists
        calenderEvents.push({
          id: booking.id,
          title: "Booked",
          start: new Date(booking.date + " " + booking.start_time),
          end: new Date(booking.date + " " + booking.end_time),
          resourceId: booking.stylist.id
        })
      })

      setResources(resourceMap)
      setEvents(calenderEvents)
      setSelectedPackage(packageId)
      setSelectedService(-1)
    }
  }

  const ShowCalander = () => {
    return (selectedService > 0 || selectedPackage > 0) ? (
      <Calendar
        min={new Date(setHours(salon["opening_time"]))}
        max={new Date(setHours(salon["closing_time"]))}
        onSelecting={() => false}
        selectable={"ignoreEvents"}
        onSelectSlot={data => {
          let baseKey = ""
          let baseList = []
          let selectedId = -1

          if (selectedService > 0) {
            baseKey="services"
            baseList = serviceList
            selectedId = selectedService
          } else if (selectedPackage > 0) {
            baseKey="packages"
            baseList = packageList
            selectedId = selectedPackage
          }

          data.end = new Date(data.start)
          data.end.setMinutes(
            data.end.getMinutes() +
            salon[baseKey][selectedId].time_duration
          )

          let c1 = moment(data.start).isAfter(moment())
          let c2 = moment(data.end).isBefore(moment(setHours(salon["closing_time"])))
          let c3 = moment(data.end).isSame(moment(setHours(salon["closing_time"])))
          let c4 = data.box && data.action === "click"
          // ////debugger
          // if( moment(data.start).isAfter(moment()) && (moment(data.end).isBefore(moment(salon.closingTime))||moment(data.end).isSame(moment(salon.closingTime))) && data.box && data.action==='click'){
          if (c1 && (c2 || c3) && c4) {
            let persons = baseList.filter(({ id }) => id === selectedId)[0].persons
            let selectedEvents = { ...selectedEvent }
            if (!selectedEvents[baseKey][selectedId]) selectedEvents[baseKey][selectedId] = []
            if (selectedEvents[baseKey][selectedId].length === persons) selectedEvents[baseKey][selectedId].shift()
            selectedEvents[baseKey][selectedId].push(data)
            setSelectedEvent(selectedEvents)
            setAllSlotsSelected(false)
          }
        }}
        events={Object.keys(selectedEvent).reduce((prev, type) => {
          return Object.keys(selectedEvent[type]).reduce((prev1, typeId) => {
            return prev1.concat(selectedEvent[type][typeId])
          }, prev)
        }, [...events])}
        localizer={localizer}
        defaultView={Views.DAY}
        views={["day"]}
        step={15}
        timeslots={4}
        components={{
          toolbar: CustomToolbar
        }}
        resources={resources}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        onNavigate={(data) => {
          setBookingDate(new Date(data))
          setAllSlotsSelected(false)
          setSelectedEvent({ packages: {}, services: {} })
        }}
      />
    ) : <h3>Select service to continue</h3>
  }

  return content ? (
    content
  ) : (
    <div>
      <div className="uk-section uk-section-muted" style={{minHeight: "calc(100vh - 80px)"}}>
        <div className="uk-container uk-container-medium">
          <div className="uk-card uk-card-default uk-card-body">
            <h2 className="uk-heading-small">{salon.name}</h2>
            <div className="flex flex-column flex-row-ns mt4">
              <div className="w-100 w-40-ns">
                <ul className="uk-list uk-list-divider mt4">
                  {serviceList.map((service) => (
                    <>
                      <li className="w-100">
                        <div id="toggle-custom" className="uk-card checkout-service-card-bg pa3 w-100">
                          <label className="flex flex-column flex-row-ns items-center-ns w-100">
                            <input className="uk-checkbox"
                                   onClick={() => serviceSelected(service.id)}
                                   checked={selectedService === service.id}
                                   type="checkbox"/>
                            <div className="flex flex-column flex-row-ns justify-between items-center-ns pb2 w-100">
                              <div className="ml3-ns">
                                <div className="uk-text-bold">
                                  {service.name}
                                </div>
                                <div className="uk-text-muted">
                                  {service.time_duration} Minutes
                                </div>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="mh3-ns mv3 mv0-ns">
                                  <div className="uk-text-bold" style={{ whiteSpace: "nowrap" }}>
                                    ₹ {service.price}
                                  </div>
                                </div>
                              </div>
                              <div className="ml3-ns uk-text-center">
                                <div className="uk-text-bold">
                                  No. of People
                                </div>
                                <div className="flex mt2">
                                  <div className="ph2 mh2 uk-text-muted">
                                    {service.persons}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
                <ul className="uk-list uk-list-divider mt4">
                  {packageList.map((singlePackage) => (
                    <>
                      <li className="w-100">
                        <div id="toggle-custom" className="uk-card checkout-service-card-bg pa3 w-100">
                          <label className="flex flex-column flex-row-ns items-center-ns w-100">
                            <input className="uk-checkbox"
                                   onClick={() => packageSelected(singlePackage.id)}
                                   checked={selectedPackage === singlePackage.id}
                                   type="checkbox"/>
                            <div className="flex flex-column flex-row-ns justify-between items-center-ns pb2 w-100">
                              <div className="ml3-ns">
                                <div className="uk-text-bold">
                                  {singlePackage.name}
                                  {/*{singlePackage.services.map(({ name }) => (<p>{name}</p>))}*/}
                                </div>
                                <div className="uk-text-muted">
                                  {singlePackage.time_duration} Minutes
                                </div>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="mh3-ns mv3 mv0-ns">
                                  <div className="uk-text-bold" style={{ whiteSpace: "nowrap" }}>
                                    ₹ {singlePackage.price}
                                  </div>
                                </div>
                              </div>
                              <div className="ml3-ns uk-text-center">
                                <div className="uk-text-bold">
                                  No. of People
                                </div>
                                <div className="flex mt2">
                                  <div className="ph2 mh2 uk-text-muted">
                                    {singlePackage.persons}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>

              </div>
              <div className="w-100 w-60-ns">
                {
                  ShowCalander()
                }
              </div>
            </div>
            <div className="flex">
              <div className="w-100 w-50-ns flex flex-wrap mb4">
                <div className="ma2">
                  <button className="uk-button uk-button-primary" type="button"
                          onClick={() => {
                            //////debugger
                            let allSelected = true
                            if (!termsAndConditions) {
                              showError("Please accept terms and conditions first")
                              return
                            }

                            for (let service of serviceList) {
                              if (!selectedEvent.services[service.id] || (service.persons !== selectedEvent.services[service.id].length)) {
                                allSelected = false
                                showError("Please select all time slots for " + service.name)
                                return
                              }
                            }
                            for (let singlePackage of packageList) {
                              if (!selectedEvent.packages[singlePackage.id] || (singlePackage.persons !== selectedEvent.packages[singlePackage.id].length)) {
                                allSelected = false
                                showError("Please select all time slots for " + singlePackage.name)
                                return
                              }
                            }
                            setAllSlotsSelected(allSelected)
                          }}>
                    Pay & Book Appointment
                  </button>
                </div>
                <div className="ma2">
                  <button className="uk-button uk-button-secondary" onClick={()=>{
                    if(typeof window !== "undefined") window.history.go(-1)
                  }}>
                    Cancel
                  </button>
                </div>
              </div>
              <div className="w-100 w-50-ns">
                <label htmlFor="termsAndConditions">Terms And Conditions</label>
                <input id={"termsAndConditions"} type="checkbox" checked={termsAndConditions}
                       onChange={() => {
                         setAllSlotsSelected(false)
                         setTermsAndConditions(!termsAndConditions)
                       }}/>
              </div>
            </div>
            <hr/>
            {allSlotsSelected && termsAndConditions &&
            <div id="booking-confirmation" className="flex flex-column flex-row-ns pv4">
              <div className="w-100 w-50-ns">
                <legend className="uk-legend uk-text-bold uk-text-secondary">
                  {userCtx.user ? "Booking For?" : "Contact Details"}
                </legend>
                <div className="flex pv3">
                  <form>
                    <fieldset className="uk-fieldset">
                      <div className="uk-margin">

                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
              <div className="w-100 w-50-ns">
                <div>
                  <table className="uk-table uk-table-small w-100 uk-background-muted">
                    {serviceList.map(service => <tr>
                      <td>
                        {service.name}
                      </td>
                      <td>
                        ₹ {service.price * service.persons}
                      </td>
                      <td>
                        No. of person {service.persons}
                      </td>
                    </tr>)
                    }
                    {packageList.map(singlePackage => <tr>
                      <td>
                        {singlePackage.name}
                      </td>
                      <td>
                        ₹ {singlePackage.price * singlePackage.persons}
                      </td>
                      <td>
                        No. of person {singlePackage.persons}
                      </td>
                    </tr>)
                    }
                  </table>
                </div>
                <div className="ph2 uk-text-muted">
                  <div>
                    Charges : ₹ {charges.toFixed(2)}
                  </div>

                  <div>
                    Discount : ₹ {discount.toFixed(2)}
                  </div>
                  <div>
                    Handling Charges : ₹ {handlingCharges.toFixed(2)}
                  </div>
                  <div>
                    Taxes : ₹ {((total - discount + handlingCharges) * 0.18).toFixed(2)}
                  </div>
                </div>
                <hr/>
                <div className="ph2">
                  <div className="uk-text-bold">
                    Total Amount : ₹ {((total - discount + handlingCharges) * 1.18).toFixed(2)}
                  </div>
                </div>
                <div className="flex flex-wrap">
                  {paid?<div className="ma2">
                    <button className="uk-button uk-button-secondary"
                            disabled={false}
                            onClick={() => {
                              prepareBookingObject(false)
                            }}
                    >
                      Confirm Booking
                    </button>
                  </div>:<><div className="ma2">
                    <button href="#booking-confirmation" className="uk-button uk-button-primary" type="button"
                            disabled={!razorCtx.available}
                            onClick={() => {
                              prepareBookingObject(!paid)
                            }}
                    >
                      Pay Online
                    </button>
                  </div>
                    <div className="ma2">
                    <button className="uk-button uk-button-secondary"
                    disabled={!razorCtx.available}
                    onClick={() => {
                    prepareBookingObject(false)
                  }}
                    >
                    Pay at Venue
                    </button>
                    </div></>}
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const CustomToolbar = props => {
  const navigate = action => {
    props.onNavigate(action)
  }

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button
          type="button"
          onClick={() => navigate("PREV")}
          style={{ marginLeft: "100px", borderColor: "white" }}
        >
          Previous Day
        </button>
        <span className="rbc-toolbar-label">{props.label}</span>
        <button
          type="button"
          onClick={() => navigate("NEXT")}
          style={{ marginLeft: "10px", borderColor: "white" }}
        >
          Next Day
        </button>
      </span>
    </div>
  )
}

export default BookingReschedule
